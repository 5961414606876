* {
  cursor: none;
}

.cursor {
  pointer-events: none;
  border: 1px solid #000;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  transition: all .2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(calc(15px - 50%), -50%);
}

.cursor2 {
  opacity: .3;
  pointer-events: none;
  z-index: 9999;
  background-color: #000;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  transition: width .3s, height .3s, opacity .3s;
  position: fixed;
  transform: translate(-50%, -50%);
}

.hover {
  opacity: .5;
  z-index: 9999;
  background-color: #1f9ad2;
}

.cursorinnerhover {
  opacity: .5;
  z-index: 9999;
  width: 50px;
  height: 50px;
}
/*# sourceMappingURL=index.1671c069.css.map */
